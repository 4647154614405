import React from 'react';
import Select, {components, ValueContainerProps} from "react-select";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props} style={{ display: "flex", alignItems: "center" }}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <label style={{ marginLeft: "8px", whiteSpace: "pre-wrap" }}>
                    {props.label}
                </label>
            </components.Option>
        </div>
    );
};

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg className="down-arrow-1"/>
            </components.DropdownIndicator>
        )
    );
};

const maxChips = 0;

const ValueContainer = ({ children, selectProps, getValue, ...props }: ValueContainerProps<any>) => {
    var length = getValue().length;
    let chips = React.Children.toArray(children).slice(0, length);
    let input = React.Children.toArray(children).slice(-1);

    const renderValue = () => {
        if(length === 0) return children;

        const inputIsEmpty =  selectProps.inputValue === "";
        const itemName = selectProps.placeholder ? (selectProps.placeholder + "'s") : "items";

        return (
            <div className="root" style={{ whiteSpace: 'nowrap' }}>
                {input}
                { inputIsEmpty && (
                    length > maxChips ?
                        <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{`${length} ${itemName}`}</span>
                        :
                        chips
                )
                }
            </div>
        )
    }

    return (
        <components.ValueContainer selectProps={selectProps} getValue={getValue} {...props}>
            {renderValue()}
        </components.ValueContainer>
    );
};

const CheckboxSelect = (props) => {
    const { options, styles, ...restProps } = props;

    return (
        <Select
            {...restProps}
            styles={{
                ...styles,
                clearIndicator: (base) => ({
                    ...base,
                    ...styles.clearIndicator,
                    padding: 0,
                }),
            }}
            components={{
                Option,
                ValueContainer,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                MultiValueRemove: () => null,
            }}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            options={options}
        />
    );
};

export default CheckboxSelect;