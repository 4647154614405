import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {components} from "react-select";
import CustomSelect from './CustomSelect';
import CheckboxSelect from './CheckboxSelect';
import {useQuery} from "@apollo/client";
import * as _ from "lodash";
import useAppContext from "../../hooks/useAppHook";
import useBehaviourContext from "../../hooks/useBehaviourHook";
import {
    getMatrixOptions,
    getMeetingDuration,
    getMeetingDurationCustomOption,
    getMeetingDurationOption,
    handleMeetingDurationCustomLabel,
} from "../helper";
import {GetCallDispositions, GetStage} from "./../core/Filters/FilterSelect";
import {
    FilterInput,
    getUsersForRoleByName,
    getUsersForRoleByNameVariables,
    teams,
    teamsVariables,
    usersByCustomer,
    usersByCustomerVariables,
} from "./../../graphql/ql/operation-result-types";
import {GET_TEAMS, GET_USERS_BY_COMPANY, GET_USERS_FOR_ROLE_BY_NAME} from "./../../graphql/ql/schema";
import FilterPanel from "../core/Filters/FilterPanel";
import {sortBy} from "../../utils/CollectionUtil";
import {EventEmitter} from "../../services/event";
import FilterCustomDateRange from "../core/Filters/FilterCustomDateRange";

const defaultFilters: FilterInput = {
    callType: null,
    teams: null,
    hosts: null,
    stageName: "",
    topics: null,
    callDisposition: null,
    leadSource: null,
    providers: null,
    hostEmails: null,
    dateMin: null,
    dateMax: null
};

const defaultFilterLabels = {
    representative: [],
    team: [],
    managerEmails: [],
    callType: null,
    meetingDate: null,
    callScope: null,
    stageName: null,
    topics: null,
    callDisposition: null,
    leadSource: null,
    providers: null,
    dateMin: null,
    dateMax: null
};

const DropDownFilters: React.FC = () => {
    const {
        user,
        setFilterData,
        setSavedFilter,
        setSaved,
        filterData,
        meetingActiveTab    // 1807
    } = useAppContext();
    const {
        setMeetingSearchTerms,
        meetingSearchTerms,
        setFilterTopNav,
        isFilterTopNav,
        setSearchText,
        setExtraLeftPanel,
        setHideMeetingPanel
    } = useBehaviourContext();

    let userTeamNames = new Array;
    if (user.teams) {
        user.teams.forEach((eachTeam) => {
            userTeamNames.push({label: eachTeam.name, value: eachTeam.name});
        });
    }

    const didMount = useRef(false);
    const [repOption, setRep] = useState([]);
    const [teamOption, setTeam] = useState([]);
    const [StageOptions, setStageOptions] = useState(null);
    const [CallDispositions, setCallDispositions] = useState(null);
    const [filters, setFilter] = useState({
        ...defaultFilters,
    });
    const [filterLabels, setFilterLabels] = useState(defaultFilterLabels);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filterForm, setFilterForm] = useState({});
    const [topics, setTopics] = useState([]);
    const [managerOptions, setManagerOptions] = useState<any[]>([]);
    const company: any = useQuery<usersByCustomer, usersByCustomerVariables>(
        GET_USERS_BY_COMPANY,
        {
            fetchPolicy: "network-only",
            variables: {customerId: user?.customer?.id},
        }
    );

    const teamval: any = useQuery<teams, teamsVariables>(GET_TEAMS, {
        fetchPolicy: "network-only",
        variables: {customerId: user.id},
    });

    const {loading, error, data: managers}: any = useQuery<getUsersForRoleByName, getUsersForRoleByNameVariables
    >( GET_USERS_FOR_ROLE_BY_NAME, {
        fetchPolicy: "network-only", variables: {
            name: 'Manager',
        }
    });

    const location = useLocation();

    useEffect(() => {
        let array: any = [];
        if (user?.customer?.skipSystemTopics?.length > 0) {
            array = user?.customer?.topics.filter((track: any) =>
                !user?.customer.skipSystemTopics.includes(track.name)
            ).map((track: any) => {
                return {label: track.name, value: track.name, type: 'topic'};
            });
        } else {
            array = user?.customer?.topics.map((track: any) => {
                return {label: track.name, value: track.name, type: 'topic'};
            });
        }
        if (user?.customer?.trackers) {
            user?.customer?.trackers?.forEach((track: any) => {
                if (!user?.customer?.topics.some(x => x.name === track?.topics)) {
                    array.push({label: track?.topics, value: track?.topics, type: 'tracker'})
                }
            });
        }
        // Remove duplicates by creating a new array with unique values
        const uniqueArray = Array.from(
            new Set(
                array?.map(a => JSON.stringify(a)) // Convert objects to strings using JSON.stringify() and map through the array
            )
        )?.map(a => JSON.parse(a as string));

        setTopics(sortBy(uniqueArray, "name", "string"));
    }, [user]);

    var showRepMeetingFeature = true, showTeamMeetingFeature = true, showCallTypeMeetingFeature = true,
        showDealStageMeetingFeature = true, showTopicsMeetingFeature = true,  showManagerMeetingFeature = true;
    var disableRepMeetingfeature = false, disableTeamMeetingfeature = false, disableCallTypeMeetingfeature = false,
        disableDealStageMeetingfeature = false, disableTopicsMeetingfeature = false, disableManagerMeetingfeature = false;
    if (meetingActiveTab == '2') {
        showRepMeetingFeature = true;//false to hide
        disableTeamMeetingfeature = true;
    } else if (meetingActiveTab == '3') {
        disableRepMeetingfeature = true;
        disableTeamMeetingfeature = true;
    }
    const isAdmin = user?.role?.name === 'Admin';
    const hideAllMeetings = user?.customer?.hideAllMeetings;
    const isManager = user?.role?.name === 'Manager';
    const isRep = (user?.role?.name === 'Rep/SDR' || user?.role?.name === 'AE/Other');

    let FilterData = [
        ...(isAdmin || !(hideAllMeetings && (isRep)) ?
                [{
                    id: "representative",
                    key: "hostEmails",
                    multi: true,
                    placeholder: "Rep",
                    option: repOption,
                    showFeature: showRepMeetingFeature,
                    disableFeature: disableRepMeetingfeature
                }] : []
        ),
        ...(isAdmin || !(hideAllMeetings && (isManager || isRep)) ?
                [{
                    id: "team",
                    key: "teams",
                    multi: true,
                    placeholder: "Team",
                    option: teamOption,
                    showFeature: showTeamMeetingFeature,
                    disableFeature: disableTeamMeetingfeature
                }] : []
        ),
        {
            id: "manager",
            key: "managerEmails",
            multi: true,
            placeholder: "Manager",
            option: managerOptions || [],
            showFeature: showManagerMeetingFeature,
            disableFeature: disableManagerMeetingfeature
        },
        {
            id: "meetingDuration",
            key: "meetingDuration",
            placeholder: "Duration",
            option: getMeetingDurationOption(),
            customTitle: "Custom Duration (in mins)",
            customOption: getMeetingDurationCustomOption(),
        },
        {
            id: "topics",
            key: "topics",
            multi: true,
            placeholder: "Topics/Trackers",
            option: topics || [],
            showFeature: showTopicsMeetingFeature,
            disableFeature: disableTopicsMeetingfeature
        },
        {
            id: "meetingDate",
            key: "meetingDate",
            placeholder: "Date Range",
        },
    ];

    useEffect(() => {
        if (company?.data?.usersByCustomer) {
            const users = _.sortBy(company.data?.usersByCustomer, [
                "firstName",
            ]);
            const RepOption: any = users?.map((item) => ({
                label: item?.firstName + " " + item?.lastName,
                value: item?.email,
            }));
            setRep(RepOption);
        }
    }, [company]);

    useEffect(() => {
        const TeamOption: any = [];
        teamval.data?.teams?.map((item) => {
            TeamOption.push({label: item?.name, value: item?.id});
            setTeam(TeamOption);
        });
    }, [teamval]);

    useEffect(() => {
        if (managers?.roleByName?.users) {
            const users = _.sortBy(managers?.roleByName.users, [
                "firstName",
            ]);
            const managerOption = users.map((item) => ({
                label: item?.firstName + " " + item?.lastName,
                value: item?.email,
            }))
            setManagerOptions(managerOption);
        }
    }, [managers]);

    useEffect(() => {
        if (StageOptions === null) {
            GetStage(user).then((stages) => {
                setStageOptions(
                    stages?.map((stage) => ({label: stage, value: stage}))
                );
            });
        }
    }, [StageOptions]);

    useEffect(() => {
        if (CallDispositions === null) {
            GetCallDispositions(user).then((callDispositions) => {
                setCallDispositions(
                    callDispositions?.map((callDisposition) => ({
                        label: callDisposition?.name,
                        value: callDisposition?.name
                    }))
                );
            });
        }
    }, []);

    useEffect(() => {
        if (didMount.current) {
            if (!setSaved) {
                let filter = {...meetingSearchTerms, ...filters,};
                setMeetingSearchTerms(filter);
                const filterValues = Object.values(filters);
                const FilterWithValues = filterValues.filter((value) =>
                    value instanceof Array ? value.length : value
                );
                if (FilterWithValues.length) {
                    if (!isFilterTopNav) {
                        setFilterTopNav(true);
                    }
                }
            }
        } else {
            didMount.current = true;
        }
    }, [filters]);

    useEffect(() => {
        const filterValues = Object.values(filters);
        const FilterWithValues = filterValues.filter((value) =>
            value instanceof Array ? value.length : value
        );
        if (FilterWithValues.length) {
            setFilterData(filterLabels);
        } else {
            const clearedState = {};
            const isCleared =
                JSON.stringify(filterData) === JSON.stringify(clearedState);
            if (!isCleared) {
                setFilterData({});
            }
            setMeetingSearchTerms({});
        }
    }, [filterLabels]);

    useEffect(() => {
        if (setSaved) {
            if (setSaved === "reset") {
                setFilter(Object.create(defaultFilters));
                setFilterLabels(Object.create(defaultFilterLabels));
                setSavedFilter(false);
            } else {
                setFilter({
                    ...defaultFilters,
                    ...meetingSearchTerms,
                });
                const getFilters = getSavedFilterLabels();
                if (getFilters) {
                    setFilterLabels(getFilters);
                    setFilterData(getFilters);
                }
            }
        }
    }, [setSaved]);

    useEffect(() => {
        EventEmitter.subscribe("search_cleared", () => {
            setFilter(Object.create(defaultFilters));
            setFilterLabels(Object.create(defaultFilterLabels));
        });
    }, [])

    const getSavedFilterLabels = () => {
        const filterWithValue = Object.keys(meetingSearchTerms).filter((data) => {
            const value = meetingSearchTerms[data];
            return value instanceof Array ? value.length : value;
        });
        if (filterWithValue.length) {
            const labelOrder = [
                "hostEmails",
                "teams",
                // "callType",
                "stageName",
            ];
            const Lables = Object.create(defaultFilterLabels);
            filterWithValue?.map((filterKey) => {
                const index = labelOrder.indexOf(filterKey);
                if (index > -1 && index < FilterData.length) {
                    //@ts-ignore
                    FilterData[index]?.option.forEach((data) => {
                        if (meetingSearchTerms[filterKey] instanceof Array) {
                            if (meetingSearchTerms[filterKey].includes(data.value)) {
                                Lables[FilterData[index].id] = [
                                    ...Lables[FilterData[index].id],
                                    data,
                                ];
                            }
                        } else if (meetingSearchTerms[filterKey] === data.value) {
                            Lables[FilterData[index].id] = data;
                        }
                    });
                }
            });
            return Lables;
        }
        return null;
    };

    const clearSearch = () => {
        setSearchText("");
    };

    const handleFilterChange = (data, selected) => {
        const {key} = data;

        // if value is selected
        if (selected && (key !== "meetingDate" || selected.value )) {
            if (key === "meetingDate" || key === "meetingDateRange") {
                setFilter({ ...filters, dateMin: selected.data.minDate, dateMax: selected.data.maxDate });
            } else if (key === "meetingDuration") {
                const temp = getMeetingDuration(selected);
                let multiplier = 60;
                if (selected.value === "custom") {
                    multiplier = 1; //conversion to secs already handled
                }
                const durationMin = parseFloat(String(temp?.split("-")[0])) * multiplier;
                const durationMax = parseFloat(String(temp?.split("-")[1])) * multiplier;
                setFilter({...filters, durationMax, durationMin});
            } else if (key === "topics") {
                let topics = Array();
                let trackers = Array();
                selected.forEach((data) => {
                    if (data.type === 'topic' && data.value) {
                        topics.push(data.value);
                    } else if (data.type === 'tracker' && data.value) {
                        trackers.push(data.value);
                    }
                });
                if (meetingSearchTerms instanceof Object && Object.keys(meetingSearchTerms).some(key => meetingSearchTerms[key])) {
                    setFilter({...meetingSearchTerms, ...filters, topics, trackers});
                } else {
                    setFilter({...filters, topics, trackers});
                }
            } else if (selected instanceof Array) {
                const values = selected?.map((data) => data.value);
                if (meetingSearchTerms instanceof Object && Object.keys(meetingSearchTerms).some(key => meetingSearchTerms[key])) {
                    setFilter({...meetingSearchTerms, ...filters, [key]: values});
                } else {
                    setFilter({...filters, [key]: values});
                }
            } else {
                setFilter({...filters, [key]: selected.value});
            }
            setFilterLabels({...filterLabels, [data.id]: selected});
            setExtraLeftPanel("");
            setHideMeetingPanel(location?.pathname === "/meetings")
        } else {
            const {dateMin, dateMax, durationMax, durationMin, crmFilter} = defaultFilters;
            if (key === "meetingDate" || key === "meetingDateRange") {
                setFilter({...filters, dateMin, dateMax});
            } else if (key === "meetingDuration") {
                setFilter({...filters, durationMax, durationMin});
            } else {
                setFilter({...filters, [key]: defaultFilters[key]});
            }
            setFilterLabels({
                ...filterLabels,
                [data.id]: defaultFilterLabels[data.id],
            });
        }
    };

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <svg className="down-arrow-1"/>
                </components.DropdownIndicator>
            )
        );
    };
    const renderRepTabOnMyMeetings = (data) => {
        return (
            <CheckboxSelect
                className="DropDownFilterPanelStyle"
                classNamePrefix="dropDownFilterPanel"
                isMulti={data.multi}
                placeholder={user.firstName}//{data.placeholder}
                components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null
                }}
                options={data.option}
                value={filterLabels[data.id]} // { {label: 'a', value: 'b'} }
                onChange={(value) => handleFilterChange(data, value)}
                styles={customStyles}
                theme={(theme) => ({...theme, borderRadius: 20})}
                isClearable
                onFocus={() => setIsOpen(false)}
                isDisabled={data.disableFeature}
                key={`unique_key_${JSON.stringify(filterLabels[data.id])}`}
            />
        )
    }
    const renderTeamsTabOnMyMeetings = (data) => {
        return (
            <CheckboxSelect
                className="DropDownFilterPanelStyle"
                classNamePrefix="dropDownFilterPanel"
                isMulti={data.multi}
                placeholder={user?.teams && user.teams[0]?.name || ""}//{data.placeholder}
                components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null
                }}
                options={data.option}//{userTeamNames}
                value={filterLabels[data.id]} // { {label: 'a', value: 'b'} }
                onChange={(value) => handleFilterChange(data, value)}
                styles={customStyles}
                theme={(theme) => ({...theme, borderRadius: 20})}
                isClearable
                onFocus={() => setIsOpen(false)}
                isDisabled={data.disableFeature}//{false}
                key={`unique_key_${JSON.stringify(filterLabels[data.id])}`}
            />
        )
    }
    const renderRepTabOnMyTeamMeetings = (data) => {
        return (
            <CheckboxSelect
                className="DropDownFilterPanelStyle"
                classNamePrefix="dropDownFilterPanel"
                isMulti={data.multi}
                placeholder={data.placeholder}//{user.firstName}
                components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null
                }}
                options={data.option}
                value={filterLabels[data.id]} // { {label: 'a', value: 'b'} }
                onChange={(value) => handleFilterChange(data, value)}
                styles={customStyles}
                theme={(theme) => ({...theme, borderRadius: 20})}
                isClearable
                onFocus={() => setIsOpen(false)}
                isDisabled={data.disableFeature}
            />
        )
    }
    const renderTeamsTabOnMyTeamMeetings = (data) => {
        return (
            <CheckboxSelect
                className="DropDownFilterPanelStyle"
                classNamePrefix="dropDownFilterPanel"
                isMulti={data.multi}
                placeholder={data.placeholder}//{user.teams[0].name}
                components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null
                }}
                options={data.option}//{userTeamNames}
                value={filterLabels[data.id]} // { {label: 'a', value: 'b'} }
                onChange={(value) => handleFilterChange(data, value)}
                styles={customStyles}
                theme={(theme) => ({...theme, borderRadius: 20})}
                isClearable
                onFocus={() => setIsOpen(false)}
                isDisabled={data.disableFeature}//{false}
                key={`unique_key_${JSON.stringify(filterLabels[data.id])}`}
            />
        )
    }
    const customStyles = {
        control: styles => ({
            ...styles,
            boxShadow: 'none',
            borderRadius: "16px",
            fontSize: "0.7rem",
            fontWeight: "normal",
            color: "#2D323C",
            paddingRight: "0.25rem",
            '&:hover': {
                backgroundColor: 'var(--topBannerColor01) !important',
                border: '1px solid var(--topBannerColor1)',
                cursor: 'pointer',
                color: "#2D323C"
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "var(--topBannerColor01)" : "white",
            color: state.isSelected ? '#70738B' : '#2D323C',
            '&:hover': {
                backgroundColor: 'var(--topBannerColor2) !important',
            }
        }),
        menu: (provided, state) => ({
            ...provided,
            width: 'auto',
            minWidth: '12rem'
        })
    };


    return (
        <div className="filter-wrapper-style">
            {FilterData?.map((data, key) => {
                if (data.key === "Metrics") {
                    return (
                        <div className="metrics-dropdown-container" key={key}>
                            <div className='metrics-dropdown-wrapper' onClick={toggle}>
                                <label className="metrics-dropdown-text">{data.key}</label>
                                <svg id="metrics-dropdown" className="metrics_dropdown_img" />
                            </div>
                            <FilterPanel
                                showAsDropdown
                                isFiltered={isFiltered}
                                setIsFiltered={setIsFiltered}
                                isOpen={isOpen}
                                toggle={toggle}
                                groups={getMatrixOptions()}
                                filterForm={filterForm}
                                setFilterForm={setFilterForm}
                                clearSearch={clearSearch}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="DropdownFilters"
                            style={{
                                minWidth: ["meetingDateRange", "stageName", "callType", "callScope"].includes(data.key) ? "6.1rem" :
                                    data.key === "topics" ? "6.8rem" :
                                        ["meetingDuration", "teams", "managerEmails", "hostEmails"].includes(data.key) ? "5.4rem" : "6.5rem",
                                marginRight: "5px",
                                fontSize: "0.8rem",
                                fontWeight: "normal",
                                height: "1rem",
                                maxHeight: "1rem !important"
                            }}
                            key={key}
                        > 
                            {data.key === "Email" && data.disableFeature && meetingActiveTab === '2' ? renderRepTabOnMyTeamMeetings(data) :
                                data.key === "hostEmails" && data.disableFeature && meetingActiveTab === '3' ? renderRepTabOnMyMeetings(data) :
                                    data.key === "teams" && data.disableFeature && meetingActiveTab === '2' ? renderTeamsTabOnMyMeetings(data) :
                                        data.key === "teams" && data.disableFeature && meetingActiveTab === '3' ? renderTeamsTabOnMyTeamMeetings(data) :
                                            data.key === "meetingDate" ? (
                                                <FilterCustomDateRange
                                                    id={data.id}
                                                    onChangeValue={(name, value) => handleFilterChange(data, value)}
                                                    text={filterLabels[data.id] || undefined}
                                                    value={filterLabels[data.id]}
                                                    placeholder={data.placeholder}
                                                    inputStyle={{fontSize: 11, minHeight: 28}}
                                                />
                                            ) : data.key === "meetingDuration" ? (
                                                <CustomSelect
                                                    className="DropDownFilterPanelStyle"
                                                    classNamePrefix="dropDownFilterPanel"
                                                    isMulti={data.multi}
                                                    placeholder={data.placeholder}
                                                    components={{
                                                        DropdownIndicator,
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    options={data.option}
                                                    value={filterLabels[data.id]}
                                                    onChange={(value) => handleFilterChange(data, value)}
                                                    styles={customStyles}
                                                    theme={(theme) => ({...theme, borderRadius: 20})}
                                                    isClearable
                                                    onFocus={() => setIsOpen(false)}
                                                    isDisabled={data.disableFeature}
                                                    key={`unique_key_${JSON.stringify(filterLabels[data.id])}`}
                                                    customOptions={data.customOption}
                                                    customTitle={data.customTitle}
                                                    handleCustomLabel={handleMeetingDurationCustomLabel}
                                                />
                                            ) : (
                                                <>
                                                    <CheckboxSelect
                                                        className="DropDownFilterPanelStyle"
                                                        classNamePrefix="dropDownFilterPanel"
                                                        isMulti={data.multi}
                                                        placeholder={data.placeholder}
                                                        options={data.option}
                                                        value={filterLabels[data.id]}
                                                        onChange={(value) => handleFilterChange(data, value)}
                                                        styles={customStyles}
                                                        theme={(theme) => ({ ...theme, borderRadius: 20 })}
                                                        isClearable
                                                        onFocus={() => setIsOpen(false)}
                                                        isDisabled={data.disableFeature}
                                                        key={`unique_key_${JSON.stringify(filterLabels[data.id])}`}
                                                     />
                                                </>
                                            )
                            }
                        </div>
                    );
                }
            })}
        </div>
    );
};

export const DropDownFilterPanel = DropDownFilters;
